import VoiceCardWithInsert from "../common/VoiceCardWithInsert";
import { BrandVoiceCategory } from "@openapi";
import { DislikeIcon } from "~/assets/icons";

const DontsCardSection = () => {
  return (
    <VoiceCardWithInsert
      title="Don'ts"
      titleStyle={{
        color: "var(--primary-vibrant-orange)",
      }}
      icon={<DislikeIcon />}
      helperText="The Don'ts are the negative actions that your brand should avoid."
      insertButtonText="Add Don't"
      category={BrandVoiceCategory.dont}
      exampleLabel="Avoid:"
    />
  );
};
export default DontsCardSection;
