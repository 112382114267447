import PrivateRoute from "../../components/core/auth/PrivateRoute";
import { CampaignWizardProvider } from "../../contexts/CampaignWizardContext";
import AdEditorPage from "../campaign/ads/edit";
import EmailEditorPage from "../campaign/email-editor";
import CampaignOverviewRoute from "../campaign/overview";
import CampaignsRoute from "../campaign/root";
import CampaignWizardPage from "../campaign/wizard";
import GeneratePage from "../campaign/wizard/generate";
import NewCampaignWizardPage from "../campaign/wizard/new";
import { HOME_ROUTE, STYLE_LIBRARY_ROUTE, CAMPAIGNS_ROUTE } from "../constants";
import HomeRoute from "../home";
import RootRoute from "../root";
import StyleLibraryRoute, { getStyleLibraryRoutes } from "../style-library";
import TemplateEditorPage from "../style-library/template-editor";
import { Theme } from "@radix-ui/themes";
import { Outlet, RouteObject } from "react-router-dom";

export default function getRoutes(): RouteObject[] {
  return [
    // Root routes (with side menu)
    {
      path: "",
      element: (
        <PrivateRoute>
          <RootRoute />
        </PrivateRoute>
      ),
      children: [
        {
          path: HOME_ROUTE,
          element: <HomeRoute />,
        },
        {
          path: STYLE_LIBRARY_ROUTE,
          element: <StyleLibraryRoute />,
          children: getStyleLibraryRoutes(),
        },
        {
          path: CAMPAIGNS_ROUTE,
          children: [{ path: "", element: <CampaignsRoute /> }],
        },
      ],
    },
    {
      path: "",
      element: (
        <Theme>
          <PrivateRoute>
            <Outlet />
          </PrivateRoute>
        </Theme>
      ),
      children: [
        {
          path: "campaign/:id",
          element: <CampaignOverviewRoute />,
        },
        {
          path: "campaign/:campaignId/email/:emailId",
          element: <EmailEditorPage />,
        },
        {
          path: "ad/:id",
          element: <AdEditorPage />,
        },
        {
          path: "email-editor/:emailId",
          element: <EmailEditorPage />,
        },
        {
          path: "style-library/template-editor",
          element: <TemplateEditorPage />,
        },
      ],
    },
    // Campaign Wizard
    {
      path: "campaigns",
      element: (
        <Theme>
          <PrivateRoute>
            <CampaignWizardProvider>
              <Outlet />
            </CampaignWizardProvider>
          </PrivateRoute>
        </Theme>
      ),
      children: [
        {
          path: "wizard/:recommendationID",
          element: <CampaignWizardPage />,
        },
        {
          path: "wizard/:campaignId/generate",
          element: <GeneratePage />,
        },
        {
          path: "wizard/new",
          element: <NewCampaignWizardPage />,
        },
      ],
    },
  ];
}
