import { operations } from "@openapi";
import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";

type CampaignResponse =
  operations["meta_api_get_meta_campaigns"]["responses"][200]["content"]["application/json"];

const useMetaCampaignsQuery = (selectedAdAccount: string | null) => {
  const {
    data: campaignsData,
    fetchNextPage: fetchNextCampaigns,
    hasNextPage: hasNextCampaigns,
    isFetchingNextPage: isFetchingNextCampaigns,
  } = useInfiniteQuery<CampaignResponse>({
    queryKey: ["campaigns", selectedAdAccount],
    queryFn: async ({ pageParam = null }) => {
      if (!selectedAdAccount) return { campaigns: [], cursor: null };
      const response = await axios.get("/api/v1/meta/campaigns", {
        params: {
          meta_ad_account_id: selectedAdAccount,
          limit: 100,
          after: pageParam,
        },
      });
      return response.data;
    },
    enabled: !!selectedAdAccount,
    getNextPageParam: (lastPage) => lastPage.cursor,
    initialPageParam: null,
  });

  return {
    campaignsData,
    fetchNextCampaigns,
    hasNextCampaigns,
    isFetchingNextCampaigns,
  };
};

export default useMetaCampaignsQuery;
