import DeleteVoiceItemDialog from "./DeleteVoiceItemDialog";
import VoiceGuidelineItemForm from "./VoiceGuidelineItemForm";
import { Flex, Heading, IconButton, Text } from "@radix-ui/themes";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { PencilIcon, Trash2Icon } from "~/assets/icons";
import DraperText from "~/components/core/DraperText";
import {
  useBrandVoiceDispatch,
  useBrandVoiceState,
} from "~/contexts/BrandVoiceContext";
import { BrandVoiceItem } from "~/hooks/voices/useBrandVoiceQuery";

const ExampleLabel = styled(Text)`
  color: var(--text-tertiary);
`;

export type VoiceGuidelineItemProps = {
  item: BrandVoiceItem;
  exampleLabel?: React.ReactNode;
};

export const VoiceGuidelineItemContainer = styled(Flex)<{
  $limitWidth?: boolean;
}>`
  max-width: ${({ $limitWidth }) => ($limitWidth ? "1000px" : "none")};
  min-width: 500px;
  flex-direction: column;
`;

const VoiceGuidelineItem: React.FC<VoiceGuidelineItemProps> = ({
  item,
  exampleLabel: inputLabel,
}) => {
  const voiceState = useBrandVoiceState();
  const voiceDispatch = useBrandVoiceDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleBeginEditing = () => {
    voiceDispatch({
      type: "PATCH",
      payload: { isEditingItem: true },
    });
    setIsEditing(true);
  };

  const handleDoneEditing = () => {
    voiceDispatch({
      type: "PATCH",
      payload: { isEditingItem: false },
    });
    setIsEditing(false);
  };

  const exampleLabel = useMemo(() => {
    if (!inputLabel) return null;
    if (typeof inputLabel === "string") {
      return <ExampleLabel>{inputLabel}</ExampleLabel>;
    }
    return inputLabel;
  }, [inputLabel]);

  if (isEditing) {
    return (
      <VoiceGuidelineItemContainer $limitWidth>
        <VoiceGuidelineItemForm onClose={handleDoneEditing} item={item} />
      </VoiceGuidelineItemContainer>
    );
  }

  return (
    <Flex direction="row" gap="6" justify="between">
      <VoiceGuidelineItemContainer direction="column" gap="3">
        <Heading size="3">{item.value}</Heading>
        <Flex direction="row" gap="2">
          {exampleLabel}
          <DraperText variant="secondary" clamp={1}>
            {item.example}
          </DraperText>
        </Flex>
      </VoiceGuidelineItemContainer>
      <Flex direction="row" gap="3" align="center">
        <IconButton
          variant="outline"
          color="gray"
          radius="large"
          disabled={voiceState.isEditingItem}
          onClick={handleBeginEditing}
        >
          <PencilIcon size={20} />
        </IconButton>
        <IconButton
          variant="outline"
          color="gray"
          radius="large"
          disabled={voiceState.isEditingItem}
          onClick={() => setIsDeleting(true)}
        >
          <Trash2Icon size={20} />
        </IconButton>
      </Flex>
      <DeleteVoiceItemDialog
        item={item}
        isOpen={isDeleting}
        onClose={() => setIsDeleting(false)}
      />
    </Flex>
  );
};

export default VoiceGuidelineItem;
