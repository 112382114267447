import CampaignStatusIndicator from "../CampaignStatusIndicator";
import { EmailCreativeStatus, AdCreativeStatus } from "@openapi";
import {
  Text,
  ScrollArea,
  IconButton,
  Popover,
  Flex,
  Button,
  TextField,
  Avatar,
  Box,
} from "@radix-ui/themes";
import _ from "lodash";
import {
  EarthIcon,
  EllipsisVerticalIcon,
  MailOpen,
  MessageCircleIcon,
  ShareIcon,
  ThumbsUpIcon,
} from "lucide-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { Calendar1Icon, PencilIcon } from "~/assets/icons";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import Modal from "~/components/core/dialog/Modal";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useMetaAdAccountsQuery from "~/hooks/meta/useMetaAdAccountsQuery";

const Root = styled.div`
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  width: 500px;
  border: 1px solid rgba(240, 240, 240, 1);
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border_light, #f0f0f0);
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 24px;
  background-color: #fcfbfa;
  border-bottom: 1px solid #ddd7d7;
  gap: 8px;
`;

const CreativeHeader = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px 16px 0 0;
  margin-top: -16px;
  padding: 16px;
  border-top: 1px solid var(--border_primary, #dddddd);
  background-color: #fff;
  border-bottom: none;
  gap: 8px;
  box-shadow: 0px 2px 8px 0px #0000000a;
`;

const AdFooter = styled(Flex)`
  background-color: #fff;
  padding: 12px;
  border-top: 1px solid var(--border_light, #f0f0f0);
`;

const ImagePreview = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`;

const ImageContainer = styled.div`
  position: relative;
  z-index: 0;
`;

const ImageTitle = styled(Text)`
  padding: 0 16px;
`;

const ScrollableContent = styled(ScrollArea)`
  flex: 1;
`;

const Foreground = styled(Link)`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  height: 0;
  width: 100%;
  opacity: 0;
  text-decoration: none;
  transition: opacity 0.3s;
  &:hover {
    opacity: 1;
  }
  overflow: visible;
`;

const ForegroundContent = styled.div`
  background: rgba(0, 0, 0, 0.32);
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: start;
  justify-content: center;
`;

const EditButton = styled(AppButton)`
  background-color: var(--primary-white);
  color: var(--primary-deep-purple);
`;

interface PreviewImage {
  title?: string;
  url: string;
}

interface CampaignOverviewCardProps {
  title: string;
  subtitle: string;
  count: number;
  previewImages: PreviewImage[];
  status: EmailCreativeStatus | AdCreativeStatus;
  publishedAt: string | null;
  variant: "email" | "ad";
  editPath: string;
  onRename: (newHeadline: string) => void;
  onDuplicate: () => void;
  onDelete: () => void;
}

const CampaignOverviewCard = ({
  title: inputTitle,
  subtitle,
  count,
  previewImages,
  status,
  publishedAt,
  variant,
  editPath,
  onRename,
  onDuplicate,
  onDelete,
}: CampaignOverviewCardProps) => {
  const activeBrandID = useActiveBrandID();
  const { adAccounts } = useMetaAdAccountsQuery(activeBrandID);
  const firstAccount = adAccounts?.[0];

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [title, setTitle] = useState(inputTitle);

  useEffect(() => {
    setTitle(inputTitle);
  }, [inputTitle]);

  let statusIndicator = <CampaignStatusIndicator status={status} />;
  if (
    status === EmailCreativeStatus.active ||
    status === AdCreativeStatus.active
  ) {
    statusIndicator = (
      <Flex
        align="center"
        gap="8px"
        style={{
          color:
            variant === "email" ? "rgba(76, 71, 71, 0.8)" : "var(--text-link)",
          cursor: "pointer",
        }}
      >
        {variant === "email" ? (
          <>
            <Calendar1Icon />
            <Text>Sent on {publishedAt}</Text>
          </>
        ) : (
          <>
            <Calendar1Icon />
            <Text>Published on {publishedAt}</Text>
          </>
        )}
      </Flex>
    );
  }
  let creativeHeader = null;
  if (variant === "email") {
    creativeHeader = (
      <CreativeHeader>
        <Flex direction="row" gap="2" align="center">
          <MailOpen size={16} />
          <Text weight="medium">{title}</Text>
        </Flex>
        <Text size="2">{subtitle}</Text>
      </CreativeHeader>
    );
  } else if (variant === "ad") {
    creativeHeader = (
      <CreativeHeader>
        <Flex direction="row" gap="2" align="center">
          <Avatar
            radius="full"
            size="2"
            src={firstAccount?.profile_picture_url || undefined}
            fallback={firstAccount?.name || ""}
          />
          <Flex direction="column">
            <Text weight="medium" size="2">
              {firstAccount?.name}
            </Text>
            <Flex direction="row" gap="2" align="center">
              <Text size="1" color="gray">
                Sponsored
              </Text>
              <EarthIcon size={12} color="gray" />
            </Flex>
          </Flex>
        </Flex>
        <Text size="2">{subtitle}</Text>
      </CreativeHeader>
    );
  }

  let creativeFooter = null;
  if (variant === "ad") {
    creativeFooter = (
      <Box>
        <Flex
          p="16px"
          justify="between"
          align="center"
          style={{ backgroundColor: "#F0F2F5" }}
        >
          <Flex direction="column">
            <Text size="2" color="gray">
              your-shop.com
            </Text>
            <Text size="3" weight="bold">
              {title}
            </Text>
          </Flex>
          <AppButton
            radius="large"
            size="3"
            style={{ backgroundColor: "rgba(0, 100, 224, 1)", color: "white" }}
          >
            Shop Now
          </AppButton>
        </Flex>
        <AdFooter gap="4" width="100%">
          <AppButton variant="ghost" radius="large" style={{ flex: 1 }}>
            <Flex gap="2" align="center">
              <ThumbsUpIcon size={16} color="gray" />
              <Text size="2" color="gray">
                Like
              </Text>
            </Flex>
          </AppButton>
          <AppButton variant="ghost" radius="large" style={{ flex: 1 }}>
            <Flex gap="2" align="center">
              <MessageCircleIcon size={16} color="gray" />
              <Text size="2" color="gray">
                Comment
              </Text>
            </Flex>
          </AppButton>
          <AppButton variant="ghost" radius="large" style={{ flex: 1 }}>
            <Flex gap="2" align="center">
              <ShareIcon size={16} color="gray" />
              <Text size="2" color="gray">
                Share
              </Text>
            </Flex>
          </AppButton>
        </AdFooter>
      </Box>
    );
  }

  return (
    <Root>
      <Header>
        <Flex justify="between" align="center" style={{ width: "100%" }}>
          <Text weight="medium" size="3">
            {variant === "email" ? "Email" : "Ad"} {count}
          </Text>

          <Popover.Root>
            <Popover.Trigger>
              <IconButton radius="large" color="gray" variant="ghost">
                <EllipsisVerticalIcon size={20} />
              </IconButton>
            </Popover.Trigger>
            <Popover.Content sideOffset={5} align="start">
              <Flex direction="column" gap="12px">
                <Popover.Close>
                  <Button
                    size="2"
                    variant="ghost"
                    color="gray"
                    radius="large"
                    onClick={onDuplicate}
                  >
                    <Text align="left" style={{ width: "100%" }}>
                      Duplicate
                    </Text>
                  </Button>
                </Popover.Close>
                <Popover.Close>
                  <Button
                    onClick={() => setIsDeleteModalOpen(true)}
                    disabled={
                      status === EmailCreativeStatus.active ||
                      status === AdCreativeStatus.active
                    }
                    size="2"
                    variant="ghost"
                    color="gray"
                    radius="large"
                  >
                    <Text align="left" style={{ width: "100%" }}>
                      Delete
                    </Text>
                  </Button>
                </Popover.Close>
                <Popover.Close>
                  <Button
                    size="2"
                    variant="ghost"
                    color="gray"
                    radius="large"
                    onClick={() => setIsRenameModalOpen(true)}
                  >
                    <Text align="left" style={{ width: "100%" }}>
                      Rename
                    </Text>
                  </Button>
                </Popover.Close>
              </Flex>
            </Popover.Content>
          </Popover.Root>
          <Modal
            onCancel={() => setIsDeleteModalOpen(false)}
            onSubmit={onDelete}
            submitBtnText="Delete"
            maxWidth="460px"
            open={isDeleteModalOpen}
            onOpenChange={(open) => setIsDeleteModalOpen(open)}
            title={`Delete ${_.capitalize(variant)}`}
          >
            <Flex p="24px">
              <Text>
                Are you sure you want to delete {title}? This action can't be
                undone.
              </Text>
            </Flex>
          </Modal>
          <Modal
            onCancel={() => setIsRenameModalOpen(false)}
            onSubmit={() => onRename(title)}
            submitBtnText="Rename"
            maxWidth="460px"
            open={isRenameModalOpen}
            onOpenChange={(open) => {
              setTitle(inputTitle);
              setIsRenameModalOpen(open);
            }}
            title="Rename"
          >
            <TextField.Root
              type="text"
              size="3"
              radius="large"
              autoFocus
              style={{
                margin: "24px",
                height: "48px",
              }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Modal>
        </Flex>

        {statusIndicator}
      </Header>
      {creativeHeader}
      <ScrollableContent>
        <Flex
          direction="column"
          style={{ position: "relative", height: "100%" }}
        >
          <Foreground to={editPath}>
            <ForegroundContent>
              <EditButton radius="large" variant="outlined" mt="8">
                <PencilIcon size={16} />
                <Text size="3">Edit</Text>
              </EditButton>
            </ForegroundContent>
          </Foreground>
          <Flex
            direction="column"
            style={{ overflowY: "auto", height: "100%" }}
          >
            {previewImages.map((image, index) => (
              <ImageContainer key={index}>
                {image.title && <ImageTitle>{image.title}</ImageTitle>}
                <ImagePreview
                  src={image.url}
                  alt={image.title || `Preview ${index + 1}`}
                />
              </ImageContainer>
            ))}
          </Flex>
        </Flex>
      </ScrollableContent>
      {creativeFooter}
    </Root>
  );
};

export default CampaignOverviewCard;
