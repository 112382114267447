import VoiceCardWithInsert from "../common/VoiceCardWithInsert";
import { BrandVoiceCategory } from "@openapi";
import { StarSparksIcon } from "~/assets/icons";

const CoreValuesCardSection = () => {
  return (
    <VoiceCardWithInsert
      title="Core Values"
      icon={<StarSparksIcon />}
      helperText={`
        Core values are the fundamental beliefs of a person or organization. 
        These guiding principles dictate behavior and can help people understand 
        the difference between right and wrong. 
        Core values also help companies to determine if they are on the right path 
        and fulfilling their goals by creating an unwavering guide.`}
      insertButtonText="New Value"
      category={BrandVoiceCategory.core_value}
    />
  );
};

export default CoreValuesCardSection;
