import { AdCreativeStatus, EmailCreativeStatus } from "@openapi";
import { Flex, Text } from "@radix-ui/themes";
import { stat } from "fs";
import _ from "lodash";
import styled from "styled-components";

const StatusDot = styled.div<{
  color: string;
  size: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";
}>`
  width: ${(props) => parseInt(props.size) * 4}px;
  height: ${(props) => parseInt(props.size) * 4}px;
  border-radius: 50%;
  background: ${(props) => props.color};
`;

const CampaignStatusIndicator = ({
  status,
  size = "2",
}: {
  status: EmailCreativeStatus | AdCreativeStatus;
  size?: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";
}) => {
  let color = "#3499F6";
  if (status === EmailCreativeStatus.draft) {
    color = "#3499F6";
  } else if (status === EmailCreativeStatus.scheduled) {
    color = "#3499F6";
  } else if (status === EmailCreativeStatus.unpublished) {
    color = "#FEB23F";
  }
  return (
    <Flex align="center" gap="8px">
      <StatusDot color={color} size={size} />
      <Text size={size} style={{ color }}>
        {_.capitalize(status)}
      </Text>
    </Flex>
  );
};

export default CampaignStatusIndicator;
