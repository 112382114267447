import VoiceGuidelineItem, {
  VoiceGuidelineItemContainer,
  VoiceGuidelineItemProps,
} from "./VoiceGuidelineItem";
import VoiceGuidelineItemForm from "./VoiceGuidelineItemForm";
import { BrandVoiceCategory } from "@openapi";
import { Flex, Separator } from "@radix-ui/themes";
import React from "react";
import { useMemo } from "react";
import styled from "styled-components";
import DraperText from "~/components/core/DraperText";
import { useBrandVoiceState } from "~/contexts/BrandVoiceContext";

const SecondarySeparator = styled(Separator)`
  background-color: var(--border-secondary);
`;

type VoiceGuidelineCategorySectionProps = {
  category: BrandVoiceCategory;
  showInsert?: boolean;
  onCloseInsert?: () => void;
} & Pick<VoiceGuidelineItemProps, "exampleLabel">;

const VoiceGuidelineCategorySection: React.FC<
  VoiceGuidelineCategorySectionProps
> = ({ category, showInsert, onCloseInsert, ...itemProps }) => {
  const voiceState = useBrandVoiceState();
  const items = useMemo(() => {
    return voiceState.items[category] ?? [];
  }, [voiceState.items, category]);

  return (
    <Flex direction="column" gap="5">
      {showInsert && (
        <VoiceGuidelineItemContainer $limitWidth>
          <VoiceGuidelineItemForm
            onClose={() => onCloseInsert?.()}
            item={{ category }}
          />
        </VoiceGuidelineItemContainer>
      )}
      {items.map((item, index) => (
        <React.Fragment key={item.id}>
          {(index !== 0 || showInsert) && <SecondarySeparator size="4" />}
          <VoiceGuidelineItem key={item.id} item={item} {...itemProps} />
        </React.Fragment>
      ))}
      {!showInsert && !items.length && (
        <DraperText variant="tertiary">
          No values have been added to this category yet.
        </DraperText>
      )}
    </Flex>
  );
};

export default VoiceGuidelineCategorySection;
