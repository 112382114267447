import { HOME_ROUTE } from "../constants";
import { AdCreativeStatus, EmailCreativeStatus } from "@openapi";
import { Flex, Heading, IconButton, Text, Tooltip } from "@radix-ui/themes";
import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import scrollIntoView from "scroll-into-view-if-needed";
import { toast } from "sonner";
import { styled } from "styled-components";
import { CheckIcon, ChevronLeftIcon, MailIcon, MetaIcon } from "~/assets/icons";
import CampaignOverviewCard from "~/components/campaign/overview/CampaignOverviewCard";
import CampaignPublishDialog from "~/components/campaign/overview/CampaignPublishDialog";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import useDeleteAdCreativeMutation from "~/hooks/ads/useDeleteAdCreativeMutation";
import useDuplicateAdCreativeMediaMutation from "~/hooks/ads/useDuplicateAdCreativeMutation";
import { useUpdateAdCreativeBaseMutation } from "~/hooks/ads/useUpdateAdCreativeMutation";
import useGetCampaignDetails from "~/hooks/campaign/useGetCampaignDetails";
import useDeleteEmailCreativeMutation from "~/hooks/emails/useDeleteEmailCreativeMutation";
import { usePatchEmailCreativeMutation } from "~/hooks/emails/usePatchEmailCreativeMutation";
import { AdMediaAspectRatioStrings } from "~/types/ads";

const Header = styled.div`
  padding: 16px 16px 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HorizontalScrollableBody = styled(Flex)`
  padding: 0 16px;
  overflow-x: auto;
`;

const HorizontalScrollGrouping = styled(Flex)`
  gap: 32px;
  scroll-margin-left: 16px;
  padding: 16px;
`;

const ButtonWrapper = styled(Flex)`
  border: 1px solid var(--border-primary);
  border-radius: 12px;
  padding: 10px;
  gap: 14px;
  background-color: var(--primary-white);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const CampaignOverviewRoute = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const emailRef = useRef<HTMLDivElement | null>(null);
  const adsRef = useRef<HTMLDivElement | null>(null);

  const {
    data: campaignDetail,
    isLoading,
    isError,
  } = useGetCampaignDetails({ campaignId: id });

  const updateAdCreative = useUpdateAdCreativeBaseMutation({
    onSuccess: ({ request_data: { headline } }) => {
      toast.success(`Ad renamed to: ${headline}`);
    },
    onError: (message) => {
      toast.error(`Failed to rename ad: ${message}`);
    },
  });

  const handleAdRename = (creativeId: string, newHeadline: string) => {
    updateAdCreative.mutate({
      creativeId,
      campaignId: id,
      request_data: {
        headline: newHeadline,
      },
    });
  };

  const deleteAdCreative = useDeleteAdCreativeMutation({
    onSuccess: ({ id }) => {
      const ad = campaignDetail?.ad_creatives.find((ad) => ad.id === id);
      if (!ad) {
        return;
      }
      toast.success(`Ad deleted: ${ad.title}`);
    },
    onError: (message) => {
      toast.error(`Failed to delete ad: ${message}`);
    },
  });

  const handleAdDelete = (creativeId: string) => {
    deleteAdCreative.mutate({
      id: creativeId,
      campaignId: id,
    });
  };

  const deleteEmailCreative = useDeleteEmailCreativeMutation({
    onSuccess: ({ id }) => {
      const email = campaignDetail?.email_creatives.find(
        (email) => email.id === id
      );
      if (!email) {
        return;
      }
      toast.success(`Email deleted: ${email.title}`);
    },
    onError: (message) => {
      toast.error(`Failed to delete email: ${message}`);
    },
  });

  const handleEmailDelete = (creativeId: string) => {
    deleteEmailCreative.mutate({
      id: creativeId,
      campaignId: id,
    });
  };

  const patchEmailCreative = usePatchEmailCreativeMutation({
    onSuccess: ({ name }) => {
      toast.success(`Email renamed to: ${name}`);
    },
    onError: (message) => {
      toast.error(`Failed to rename email: ${message}`);
    },
  });

  const handleEmailRename = (creativeId: string, newName: string) => {
    patchEmailCreative.mutate({
      id: creativeId,
      campaignId: id,
      name: newName,
    });
  };

  const duplicateAdCreative = useDuplicateAdCreativeMediaMutation({
    onSuccess: (data) => {
      toast.success(`Ad copy created: ${data.title}`);
      setTimeout(() => {
        handleScroll("ads", "end");
      }, 50);
    },
    onError: (message) => {
      toast.error(`Failed to duplicate ad: ${message}`);
    },
  });

  const handleDuplicateAd = (creativeId: string) => {
    duplicateAdCreative.mutate({
      adCreativeId: creativeId,
      campaignId: id,
    });
  };

  const handleScroll = (
    campaignType: string,
    position?: ScrollLogicalPosition
  ) => {
    if (campaignType === "email" && emailRef.current) {
      scrollIntoView(emailRef.current, {
        behavior: "smooth",
        inline: position ?? "start",
      });
    } else if (campaignType === "ads" && adsRef.current) {
      scrollIntoView(adsRef.current, {
        behavior: "smooth",
        inline: position ?? "start",
      });
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error</div>;
  if (!campaignDetail) return <div>No campaign data found</div>;

  return (
    <Flex direction="column" width="100%" height={"100vh"}>
      <Header>
        <Flex direction="row" gap="3" align="center" justify="between">
          <AppButton
            variant="outlined"
            style={{
              background: "var(--primary-white)",
            }}
            onClick={() => navigate(HOME_ROUTE)}
          >
            <ChevronLeftIcon size="20" />
            <Text as="span">Home</Text>
          </AppButton>
          <Heading size="5" weight={"bold"}>
            {campaignDetail.title}
          </Heading>
        </Flex>

        <ButtonWrapper>
          <Tooltip content="Scroll to Emails">
            <IconButton
              onClick={() => handleScroll("email")}
              radius="large"
              variant="ghost"
              color="violet"
            >
              <MailIcon />
            </IconButton>
          </Tooltip>
          <Tooltip content="Scroll to Meta Ads">
            <IconButton
              onClick={() => handleScroll("ads")}
              radius="large"
              variant="ghost"
              color="violet"
            >
              <MetaIcon size={24} />
            </IconButton>
          </Tooltip>
        </ButtonWrapper>

        {campaignDetail.email_creatives.every(
          (email) => email.status === EmailCreativeStatus.active
        ) &&
        campaignDetail.ad_creatives.every(
          (ad) => ad.status === AdCreativeStatus.active
        ) ? (
          <AppButton
            variant="outlined"
            style={{ background: "var(--primary-white)" }}
          >
            <CheckIcon /> Published
          </AppButton>
        ) : (
          <CampaignPublishDialog campaign={campaignDetail} />
        )}
      </Header>

      <HorizontalScrollableBody>
        <HorizontalScrollGrouping ref={emailRef}>
          {campaignDetail.email_creatives.map((email, index) => (
            <CampaignOverviewCard
              key={email.id}
              title={email.title}
              subtitle={email.subtitle}
              count={index + 1}
              variant="email"
              editPath={`/campaign/${id}/email/${email.id}`}
              status={email.status}
              publishedAt={email.published_at}
              previewImages={
                email.preview_image
                  ? [{ url: email.preview_image, title: undefined }]
                  : []
              }
              onRename={(newHeadline) =>
                handleEmailRename(email.id, newHeadline)
              }
              onDuplicate={() => {
                console.log("Duplicate");
              }}
              onDelete={() => handleEmailDelete(email.id)}
            />
          ))}
        </HorizontalScrollGrouping>
        <HorizontalScrollGrouping ref={adsRef}>
          {campaignDetail.ad_creatives.map((ad, index) => (
            <CampaignOverviewCard
              key={ad.id}
              title={ad.title}
              subtitle={ad.description}
              count={index + 1}
              variant="ad"
              editPath={`/ad/${ad.id}`}
              status={ad.status}
              publishedAt={null}
              previewImages={ad.preview_images.map((image) => ({
                url: image.url,
                title: AdMediaAspectRatioStrings[image.aspect_ratio],
              }))}
              onRename={(newHeadline) => {
                handleAdRename(ad.id, newHeadline);
              }}
              onDuplicate={() => handleDuplicateAd(ad.id)}
              onDelete={() => handleAdDelete(ad.id)}
            />
          ))}
        </HorizontalScrollGrouping>
      </HorizontalScrollableBody>
    </Flex>
  );
};

export default CampaignOverviewRoute;
