import { operations } from "@openapi";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

type BusinessPageResponse =
  operations["meta_api_get_meta_business_pages"]["responses"][200]["content"]["application/json"];

const useMetaBusinessPagesQuery = (selectedAdAccount: string | null) => {
  const { data: businessPages } = useQuery<BusinessPageResponse["data"]>({
    queryKey: ["businessPages", selectedAdAccount],
    queryFn: async () => {
      if (!selectedAdAccount) return [];
      const response = await axios.get<BusinessPageResponse>(
        "/api/v1/meta/business-pages",
        {
          params: {
            meta_ad_account_id: selectedAdAccount,
          },
        }
      );
      return response.data.data;
    },
    enabled: !!selectedAdAccount,
  });

  return {
    businessPages,
  };
};

export default useMetaBusinessPagesQuery;
