import { TextField, Text } from "@radix-ui/themes";
import { Clock } from "lucide-react";
import * as React from "react";
import styled from "styled-components";

const TimeInput = styled(TextField.Root)`
  cursor: pointer;
  background: white;
  input[type="time"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    z-index: 2;
    background: transparent;
    &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
      background: none;
    }
  }
`;

const TimePickerRoot = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
`;

const CustomTimePicker = ({
  onSelect,
}: {
  onSelect?: (time: string) => void;
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.showPicker();
    }
  };

  return (
    <TimePickerRoot onClick={handleClick}>
      <TimeInput
        style={{
          height: "48px",
          borderRadius: "12px",
          padding: "12px",
          background: "white",
          cursor: "pointer",
        }}
        type="time"
        ref={inputRef}
        onChange={(e) => {
          onSelect?.(e.target.value);
        }}
      >
        <TextField.Slot style={{ cursor: "pointer" }}>
          <Clock size={20} />
        </TextField.Slot>
      </TimeInput>
      <Text size="2">
        {
          Intl.DateTimeFormat("en-US", {
            timeZoneName: "short",
          })
            .formatToParts(new Date())
            .filter((p) => p.type === "timeZoneName")?.[0]?.value
        }
      </Text>
    </TimePickerRoot>
  );
};

export default CustomTimePicker;
