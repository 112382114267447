import ChatMessageContainer from "../../chat/ChatMessageContainer";
import SpinningLoader from "../../core/SpinningLoader";
import AppButton from "../../core/buttons/AppButton/AppButton";
import { operations } from "@openapi";
import { Flex, Text, TextField, Tooltip } from "@radix-ui/themes";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "sonner";
import styled from "styled-components";
import { CheckIcon } from "~/assets/icons";
import DatePicker from "~/components/core/inputs/DatePicker";
import CustomTimePicker from "~/components/core/inputs/TimePicker";
import { getCampaignDetailsQueryKey } from "~/hooks/campaign/useGetCampaignDetails";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

const Root = styled(Flex)`
  border-right: 1px solid var(--border-primary);
  width: 430px;
  flex-shrink: 0;
  height: 100%;
  padding: 24px;
  gap: 24px;
`;

const MessageContainer = styled(Flex)`
  padding: 24px;
  border-radius: 16px;
  border: 1px solid var(--border-primary);
  justify-content: center;
  align-items: center;
`;

const SchedulingUI = styled(Flex)`
  border-radius: 12px;
  border: 1px solid var(--border-primary);
  padding: 16px;
`;

type EmailPublishRequest =
  operations["emails_api_publish_emails"]["requestBody"]["content"]["application/json"];
type EmailPublishResponse =
  operations["emails_api_publish_emails"]["responses"]["200"]["content"]["application/json"];

const CampaignPublishDialogEmailContainer = ({
  campaignId,
  selectedEmails,
  errorMessage,
  isDisabled,
  setPublishState,
  publishState,
  toggleSelectedCampaignType,
}: {
  campaignId: string;
  selectedEmails: string[];
  errorMessage: string;
  isDisabled: boolean;
  setPublishState: (
    state: "initial" | "loading" | "success" | "scheduling"
  ) => void;
  publishState: "initial" | "loading" | "success" | "scheduling";
  toggleSelectedCampaignType: () => void;
}) => {
  const queryClient = useQueryClient();
  const [scheduleData, setScheduleData] = useState<{
    [key: string]: {
      date: string;
      time: string;
    };
  }>({});

  const publishEmailsMutation = useDraperApiPostMutation<
    EmailPublishResponse,
    EmailPublishRequest
  >({
    path: "/emails/publish",
    mutationKey: ["publish-emails"],
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: getCampaignDetailsQueryKey(campaignId),
      });
    },
    onSuccess: () => {
      setPublishState("success");
      toast.success("Emails published successfully");
    },
    onError: (error) => {
      console.error("Error publishing emails:", error);
      setPublishState("initial");
      toast.error("Failed to publish emails");
    },
  });

  const handleSendToDraft = () => {
    setPublishState("loading");
    publishEmailsMutation.mutate({
      creatives: selectedEmails.map((id) => ({ id })),
    });
  };

  const handleSchedule = () => {
    setPublishState("loading");
    publishEmailsMutation.mutate({
      creatives: selectedEmails.map((id) => ({
        id,
        publish_timestamp: scheduleData[id]?.date
          ? new Date(
              `${scheduleData[id].date}T${scheduleData[id].time}`
            ).toISOString()
          : undefined,
      })),
    });
  };

  const schedulingUI = (
    <SchedulingUI direction="column" gap="16px">
      {selectedEmails.map((id, index) => (
        <Flex key={id} direction="column" gap="8px">
          <Text weight="medium">Schedule Email {index + 1}:</Text>
          <Flex gap="8px">
            <DatePicker
              onSelect={(formattedDate) => {
                setScheduleData((prev) => ({
                  ...prev,
                  [id]: {
                    date: formattedDate,
                    time: prev[id]?.time || "12:00",
                  },
                }));
              }}
            />
            <CustomTimePicker
              onSelect={(formattedTime) => {
                setScheduleData((prev) => ({
                  ...prev,
                  [id]: {
                    date: prev[id]?.date || "",
                    time: formattedTime,
                  },
                }));
              }}
            />
          </Flex>
        </Flex>
      ))}
      <Flex justify="end" align="center" gap="2">
        <AppButton
          disabled={publishEmailsMutation.isPending}
          radius="large"
          size="4"
          variant="outlined"
          onClick={() => setPublishState("initial")}
        >
          Cancel
        </AppButton>
        <AppButton
          id="submit-button"
          disabled={
            publishEmailsMutation.isSuccess || publishEmailsMutation.isPending
          }
          radius="large"
          size="4"
          variant="dark"
          onClick={handleSchedule}
        >
          Schedule
        </AppButton>
      </Flex>
    </SchedulingUI>
  );

  const scheduleButton = errorMessage ? (
    <Tooltip content={errorMessage}>
      <AppButton
        disabled={isDisabled}
        variant="outlined"
        size="4"
        radius="large"
        onClick={() => setPublishState("scheduling")}
      >
        Schedule
      </AppButton>
    </Tooltip>
  ) : (
    <AppButton
      disabled={isDisabled}
      variant="outlined"
      size="4"
      radius="large"
      onClick={() => setPublishState("scheduling")}
    >
      Schedule
    </AppButton>
  );
  const sendToKlaviyoButton = errorMessage ? (
    <Tooltip content={errorMessage}>
      <AppButton
        disabled={isDisabled}
        variant="outlined"
        size="4"
        radius="large"
        onClick={handleSendToDraft}
      >
        Send to Klaviyo as draft
      </AppButton>
    </Tooltip>
  ) : (
    <AppButton
      disabled={isDisabled}
      variant="outlined"
      size="4"
      radius="large"
      onClick={handleSendToDraft}
    >
      Send to Klaviyo as draft
    </AppButton>
  );

  return (
    <Root direction={"column"} gap="2">
      <ChatMessageContainer
        message={`You have ${
          selectedEmails.length === 1
            ? "1 email"
            : `${selectedEmails.length} emails`
        } to schedule, or you can send them to Klaviyo as drafts.`}
      />
      {publishState === "initial" && (
        <Flex gap="16px" wrap="wrap">
          {scheduleButton}
          {sendToKlaviyoButton}
        </Flex>
      )}
      {publishState === "scheduling" && schedulingUI}
      {publishState === "loading" && (
        <MessageContainer>
          <Flex gap="12px" align="center">
            <SpinningLoader />
            <Text>Sending draft emails to Klaviyo...</Text>
          </Flex>
        </MessageContainer>
      )}
      {publishState === "success" && (
        <>
          <MessageContainer>
            <Flex gap="12px" align="center">
              <CheckIcon />
              <Text>Emails sent to Klaviyo as drafts</Text>
            </Flex>
          </MessageContainer>
          <ChatMessageContainer message="Should we schedule them as well? Or should we move on to the Meta ads?" />
          <Flex gap="16px">
            <AppButton
              onClick={() => setPublishState("scheduling")}
              variant="outlined"
              size="4"
              radius="large"
            >
              Schedule
            </AppButton>
            <AppButton
              onClick={toggleSelectedCampaignType}
              variant="outlined"
              size="4"
              radius="large"
            >
              Review Meta Ads
            </AppButton>
          </Flex>
        </>
      )}
    </Root>
  );
};

export default CampaignPublishDialogEmailContainer;
