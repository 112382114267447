import { toast } from "sonner";
import TextAreaForm from "~/components/core/forms/TextAreaForm";
import { useBrandVoiceState } from "~/contexts/BrandVoiceContext";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import { usePatchBrandVoiceMutation } from "~/hooks/voices/usePatchBrandVoiceQuery";

interface EditMissionStatementProps {
  onClose: () => void;
}

const EditMissionStatement: React.FC<EditMissionStatementProps> = ({
  onClose,
}) => {
  const activeBrandID = useActiveBrandID();
  const { missionStatement } = useBrandVoiceState();
  const { mutate, isPending } = usePatchBrandVoiceMutation({
    onSuccess() {
      toast.success("Mission statement updated");
      onClose();
    },
    onError(message) {
      toast.error(message);
    },
  });

  const handleSave = (value: string) => {
    mutate({ mission_statement: value, brandId: activeBrandID });
  };

  return (
    <TextAreaForm
      isLoading={isPending}
      defaultValue={missionStatement}
      onSave={handleSave}
      onCancel={onClose}
    />
  );
};

export default EditMissionStatement;
