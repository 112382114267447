import AppButton from "../buttons/AppButton/AppButton";
import * as Form from "@radix-ui/react-form";
import { Flex, Heading, TextArea } from "@radix-ui/themes";
import { useCallback, useState } from "react";

type TextAreaFormProps = {
  isLoading: boolean;
  defaultValue: string;
  onSave: (value: string) => void;
  onCancel: () => void;
  serverErrors?: string;
  onClearServerErrors?: () => void;
};

const TextAreaForm: React.FC<TextAreaFormProps> = ({
  isLoading,
  defaultValue,
  onSave,
  onCancel,
  serverErrors,
  onClearServerErrors,
}) => {
  const [hasChanges, setHasChanges] = useState(false);
  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      onSave(data.get("text") as string);
    },
    [onSave]
  );

  return (
    <Form.Root
      onSubmit={handleSubmit}
      onClearServerErrors={onClearServerErrors}
    >
      <Flex direction="column" gap="5">
        <Form.Field name="text">
          <Flex direction={"column"}>
            <Form.Control asChild>
              <TextArea
                required
                disabled={isLoading}
                defaultValue={defaultValue}
                onChange={() => {
                  setHasChanges(true);
                }}
                size="3"
                color="gray"
                radius="large"
              />
            </Form.Control>
            <Form.Message match="valueMissing" style={{ color: "red" }}>
              Please enter some text
            </Form.Message>
          </Flex>
        </Form.Field>

        <Flex justify="end" gap="3" align="center">
          <AppButton
            variant="outlined"
            radius="large"
            disabled={isLoading}
            onClick={onCancel}
          >
            Cancel
          </AppButton>
          <Form.Submit asChild>
            <AppButton
              variant="dark"
              radius="large"
              disabled={!hasChanges || isLoading}
            >
              Save Changes
            </AppButton>
          </Form.Submit>
        </Flex>
        {serverErrors ? (
          <Heading mt="2" size="2" color="red" align={"right"}>
            {serverErrors}
          </Heading>
        ) : (
          <></>
        )}
      </Flex>
    </Form.Root>
  );
};

export default TextAreaForm;
