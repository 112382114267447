import VoiceCardWithInsert from "../common/VoiceCardWithInsert";
import { BrandVoiceCategory } from "@openapi";
import { HornIcon } from "~/assets/icons";

const OverallCharacteristicsCardSection = () => {
  return (
    <VoiceCardWithInsert
      title="Overall Voice Characteristics"
      icon={<HornIcon />}
      helperText={`
        A brand's overall voice characteristics are the general attributes that define the brand's voice. 
        These characteristics are the foundation of the brand's voice and should be consistent across all 
        communication channels.
      `}
      insertButtonText="New Characteristics"
      category={BrandVoiceCategory.overall_characteristic}
    />
  );
};

export default OverallCharacteristicsCardSection;
