import { useBrandVoiceQueryDataPatch } from "./useBrandVoiceQuery";
import { operations } from "@openapi";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";

export type PatchBrandVoiceRequestData =
  operations["brand_api_patch_brand_voices"]["requestBody"]["content"]["application/json"] & {
    brandId: string;
  };
type PatchBrandVoiceResponse =
  operations["brand_api_patch_brand_voices"]["responses"][200]["content"]["application/json"];

export const usePatchBrandVoiceMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (
    data: PatchBrandVoiceResponse,
    vars: PatchBrandVoiceRequestData
  ) => void;
  onError?: (message: string, error?: AxiosError) => void;
}) => {
  const patchQueryData = useBrandVoiceQueryDataPatch();
  return useMutation<
    PatchBrandVoiceResponse,
    AxiosError | Error,
    PatchBrandVoiceRequestData
  >({
    mutationFn: async ({ brandId, ...requestData }) => {
      const { data } = await axios.patch(
        `/api/v1/brand/${brandId}/voice`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (data, vars) => {
      onSuccess?.(data, vars);

      if (!vars.brandId) {
        return;
      }
      patchQueryData({
        brandId: vars.brandId,
        patchData: {
          missionStatement: vars.mission_statement ?? undefined,
          newValues: data.new_values ?? undefined,
          updatedValues: vars.update_values ?? undefined,
          removedValueIds: vars.delete_values ?? undefined,
        },
      });
    },
    onError: (error) => {
      console.log("Email creative patch failed:", error);
      const axiosError = axios.isAxiosError(error) ? error : undefined;
      const errorMessage =
        axiosError && axiosError.status !== 500 && axiosError.response?.data
          ? String(axiosError.response?.data)
          : "Something went wrong";
      onError?.(errorMessage, axiosError);
    },
  });
};
