import { Flex, Text } from "@radix-ui/themes";
import { toast } from "sonner";
import Modal from "~/components/core/dialog/Modal";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import { BrandVoiceItem } from "~/hooks/voices/useBrandVoiceQuery";
import { usePatchBrandVoiceMutation } from "~/hooks/voices/usePatchBrandVoiceQuery";

interface VoiceGuidelineItemFormProps {
  item: BrandVoiceItem;
  isOpen: boolean;
  onClose: () => void;
}

const DeleteVoiceItemDialog: React.FC<VoiceGuidelineItemFormProps> = ({
  item,
  isOpen,
  onClose,
}) => {
  const activeBrandID = useActiveBrandID();
  const { mutate, isPending: isLoading } = usePatchBrandVoiceMutation({
    onSuccess() {
      onClose();
    },
    onError(message) {
      toast.error(message);
    },
  });

  const handleDelete = () => {
    mutate({
      brandId: activeBrandID,
      delete_values: [item.id],
    });
  };

  return (
    <Modal
      onCancel={onClose}
      onSubmit={handleDelete}
      submitBtnText="Delete"
      loading={isLoading}
      maxWidth="460px"
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
      title="Delete Values"
    >
      <Flex direction="column" p="5">
        <Text>
          Are you sure you want to delete <b>{item.value}</b> value?
        </Text>
        <Text>This action can't be undone.</Text>
      </Flex>
    </Modal>
  );
};

export default DeleteVoiceItemDialog;
