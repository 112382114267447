import SearchableExpandedSelect from "../campaign/wizard/inputs/SearchableExpandedSelect";
import AppButton from "../core/buttons/AppButton/AppButton";
import { Dialog, Flex, Text, IconButton } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { XIcon } from "~/assets/icons";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useBrandQuery from "~/hooks/brand/useBrandQuery";
import { EmailCollectionGridSectionSchema } from "~/utils/emails/useSectionTypeCheck";

interface CollectionSelectorDialogProps {
  onConfirm: (collectionIds: string[]) => void;
  triggerComponent: React.ReactNode;
  min: number;
  max: number;
  initialSelectedCollections: string[];
}

const CollectionSelectorDialog: React.FC<CollectionSelectorDialogProps> = ({
  onConfirm,
  triggerComponent,
  min,
  max,
  initialSelectedCollections,
}) => {
  const [selectedCollections, setSelectedCollections] = useState<string[]>(
    initialSelectedCollections ?? []
  );
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setSelectedCollections(initialSelectedCollections ?? []);
  }, [initialSelectedCollections]);

  const header = (
    <Flex direction="row" justify="between" align="center">
      <Dialog.Title m="0">Select Collections to Feature</Dialog.Title>
      <Dialog.Close>
        <IconButton
          style={{
            borderRadius: "8px",
            cursor: "pointer",
          }}
          color="gray"
          variant="outline"
        >
          <XIcon color="#000000A6" />
        </IconButton>
      </Dialog.Close>
    </Flex>
  );

  return (
    <Dialog.Root>
      <Dialog.Trigger>{triggerComponent}</Dialog.Trigger>
      <Dialog.Content>
        <Flex direction="column" gap="4">
          {header}

          <Dialog.Description>
            Select at least {min} and up to {max} collections to feature in your
            campaign.
          </Dialog.Description>

          <SearchableExpandedSelect
            showExternalLink={false}
            height="296px"
            onSelect={(collection) => {
              if (error) {
                setError(null);
              }

              const isSelected = selectedCollections.find(
                (c) => c === collection.id
              );

              if (isSelected && selectedCollections.length > min) {
                setSelectedCollections(
                  selectedCollections.filter((c) => c !== collection.id)
                );
              } else if (!isSelected && selectedCollections.length < max) {
                setSelectedCollections([...selectedCollections, collection.id]);
              } else {
                if (selectedCollections.length <= min) {
                  setError(
                    `You must select at least ${min} ${
                      min === 1 ? "collection" : "collections"
                    }`
                  );
                } else {
                  setError(`You can only select up to ${max} collections`);
                }
              }
            }}
            selected={selectedCollections}
          />

          {error && <Text color="red">{error}</Text>}

          <Flex justify="end" pt="16px">
            <Flex gap="3">
              <Dialog.Close>
                <AppButton variant="outlined">Cancel</AppButton>
              </Dialog.Close>
              <Dialog.Close>
                <AppButton
                  variant="dark"
                  onClick={() => onConfirm(selectedCollections)}
                  disabled={
                    selectedCollections.length < min ||
                    selectedCollections.length > max
                  }
                >
                  Add
                </AppButton>
              </Dialog.Close>
            </Flex>
          </Flex>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default CollectionSelectorDialog;
