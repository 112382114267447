import VoiceCardWithInsert from "../common/VoiceCardWithInsert";
import { BrandVoiceCategory } from "@openapi";
import { LikeIcon } from "~/assets/icons";

const DosCardSection = () => {
  return (
    <VoiceCardWithInsert
      title="Dos"
      titleStyle={{
        color: "#009211",
      }}
      icon={<LikeIcon />}
      helperText="The Dos are the positive actions that your brand should take."
      insertButtonText="Add do"
      category={BrandVoiceCategory.do}
      exampleLabel="Example:"
    />
  );
};

export default DosCardSection;
