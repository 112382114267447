import ModifierContainer from "./ModifierContainer";
import { Cross2Icon, ImageIcon } from "@radix-ui/react-icons";
import { Flex, Avatar, IconButton } from "@radix-ui/themes";
import { GripVertical } from "lucide-react";
import { useState } from "react";
import { DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import styled from "styled-components";
import { PencilIcon } from "~/assets/icons";
import AssetSelectorDialog from "~/components/campaign/wizard/dialogs/asset-selector-dialog/AssetSelectorDialog";
import DraperText from "~/components/core/DraperText";
import { StrictModeDroppable } from "~/components/core/StrictModeDroppable";
import SidePanelComponents from "~/components/core/editor/sidepane";
import CollectionSelectorDialog from "~/components/dialogs/CollectionSelectorDialog";
import { cleanHtmlText } from "~/components/editor/utils";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import useShopifyProductsQuery from "~/hooks/shopify/useShopifyProductsQuery";
import {
  useEmailState,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import {
  EmailCollectionGridSectionSchema,
  isCollectionGridSection,
} from "~/utils/emails/useSectionTypeCheck";

const ProductInfo = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
  margin-left: 12px;
`;

const AvatarWrapper = styled.div`
  position: relative;
  cursor: pointer;
  border-radius: var(--radius-3);
  overflow: hidden;
`;

const AvatarOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

const CollectionRowItem = ({
  collection,
  onDelete,
  onImageClick,
  hideDragHandle,
}: {
  collection: EmailCollectionGridSectionSchema["collections"][number];
  onDelete?: () => void;
  onImageClick?: () => void;
  hideDragHandle?: boolean;
}) => {
  const [hoveredProductId, setHoveredProductId] = useState<string | null>(null);
  const avatar = (
    <Avatar
      alt={cleanHtmlText(collection.title.text)}
      src={collection.background_image.image_url}
      fallback=""
      style={{
        overflow: "hidden",
      }}
    />
  );

  return (
    <Flex gap="2" align="center" p="2" key={collection.id}>
      {!hideDragHandle && (
        <GripVertical
          color="#595D62"
          size={20}
          style={{
            cursor: "grab",
          }}
        />
      )}
      {onImageClick ? (
        <AvatarWrapper
          onMouseEnter={() =>
            setHoveredProductId(collection.commerce_platform_item_id)
          }
          onMouseLeave={() => setHoveredProductId(null)}
          onClick={onImageClick}
        >
          {avatar}
          {hoveredProductId === collection.commerce_platform_item_id && (
            <AvatarOverlay style={{ borderRadius: "inherit" }}>
              <ImageIcon color="white" />
            </AvatarOverlay>
          )}
        </AvatarWrapper>
      ) : (
        avatar
      )}
      <ProductInfo>
        <DraperText size="2" weight="medium" clamp={1}>
          {cleanHtmlText(collection.title.text)}
        </DraperText>
      </ProductInfo>
      {onDelete && (
        <IconButton
          variant="ghost"
          color="gray"
          radius="large"
          onClick={onDelete}
        >
          <Cross2Icon />
        </IconButton>
      )}
    </Flex>
  );
};

const CollectionModifier = ({
  collections,
  onCollectionChange,
  onDragEnd,
  min = 1,
  max = 4,
}: {
  collections: EmailCollectionGridSectionSchema["collections"];
  onCollectionChange: (collectionIds: string[]) => void;
  onDragEnd?: (
    collections: EmailCollectionGridSectionSchema["collections"]
  ) => void;
  min?: number;
  max?: number;
}) => {
  const updateSection = useUpdateSectionField();
  const { selectedSectionId, sections, related_commerce_item_ids } =
    useEmailState();
  const selectedSection = sections.find(
    (section) => section.id === selectedSectionId
  );
  const activeBrandID = useActiveBrandID();
  const [productIndexToUpdate, setProductIndexToUpdate] = useState<
    number | null
  >(null);

  const { productsData } = useShopifyProductsQuery(
    activeBrandID,
    related_commerce_item_ids
  );
  const relatedAssets = productsData
    ?.filter((product) => product.image_url)
    .map((product) => ({ url: product.image_url as string }));

  if (!selectedSection || !isCollectionGridSection(selectedSection)) {
    return null;
  }

  const collectionDialog = (
    <CollectionSelectorDialog
      initialSelectedCollections={collections.map(
        (c) => c.commerce_platform_item_id
      )}
      onConfirm={onCollectionChange}
      triggerComponent={
        <SidePanelComponents.Button>
          <PencilIcon />
          Change
        </SidePanelComponents.Button>
      }
      min={min}
      max={max}
    />
  );

  const multiItemDraggableArea =
    collections.length === 1 ? (
      <>
        <CollectionRowItem collection={collections[0]} hideDragHandle />
        {collectionDialog}
      </>
    ) : (
      <DragDropContext
        onDragEnd={(result: DropResult) => {
          const { destination, source } = result;

          if (!destination) {
            return;
          }

          if (
            destination.droppableId !== source.droppableId &&
            destination.index === source.index
          ) {
            return;
          }
          const newCollections = Array.from(collections);
          const [reorderedCollection] = newCollections.splice(source.index, 1);
          newCollections.splice(destination.index, 0, reorderedCollection);
          onDragEnd?.(newCollections);
        }}
      >
        <Flex direction="column" gap="1">
          <StrictModeDroppable
            droppableId="product-modifier-droppable"
            type="group"
          >
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {collections.map((collection, index) => (
                  <Draggable
                    key={collection.commerce_platform_item_id}
                    draggableId={collection.commerce_platform_item_id}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          cursor: "pointer",
                          position: "relative",
                          ...provided.draggableProps.style,
                        }}
                      >
                        <CollectionRowItem
                          collection={collection}
                          onDelete={() => {
                            updateSection({
                              sectionId: selectedSectionId,
                              field: "collections",
                              value: collections.filter(
                                (c) =>
                                  c.commerce_platform_item_id !==
                                  collection.commerce_platform_item_id
                              ),
                            });
                          }}
                          onImageClick={() => {
                            setProductIndexToUpdate(index);
                          }}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </StrictModeDroppable>

          {collectionDialog}
        </Flex>

        <AssetSelectorDialog
          singleAsset
          isDialogOpen={productIndexToUpdate !== null}
          onToggleDialogOpen={(open) => {
            if (open === false) {
              setProductIndexToUpdate(null);
            }
          }}
          onSelect={(asset) => {
            if (productIndexToUpdate === null) {
              return;
            }
            updateSection({
              sectionId: selectedSectionId,
              field: `collections.${productIndexToUpdate}.background_image.image_url`,
              value: asset[0].url,
            });
            setProductIndexToUpdate(null);
          }}
          relatedAssets={relatedAssets}
          preselectedAssets={
            productIndexToUpdate
              ? [
                  {
                    url: selectedSection.collections[productIndexToUpdate]
                      .background_image.image_url,
                  },
                ]
              : []
          }
        />
      </DragDropContext>
    );

  return (
    <ModifierContainer title="Collections" hideSwitch>
      {multiItemDraggableArea}
    </ModifierContainer>
  );
};

export default CollectionModifier;
