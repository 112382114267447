import { Box } from "@radix-ui/themes";
import styled from "styled-components";

const Card = styled(Box)`
  border: 1px solid var(--border-primary);
  border-radius: 12px;
  padding: 24px;
  background-color: var(--primary-white);
  min-width: 600px;
`;

interface StyleLibraryPageProps {
  sections: (() => JSX.Element)[];
}

const StyleLibraryPage: React.FC<StyleLibraryPageProps> = ({ sections }) => {
  return (
    <>
      {sections.map((Section, index) => (
        <Card key={index}>
          <Section />
        </Card>
      ))}
    </>
  );
};

export default StyleLibraryPage;
