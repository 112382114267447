import { useActiveBrandID } from "../../contexts/CurrentUserContext";
import { operations } from "@openapi";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import _ from "lodash";

export type BrandVoiceResponse =
  operations["brand_api_get_brand_voices"]["responses"][200]["content"]["application/json"];

export type BrandVoiceItem = BrandVoiceResponse["values"][0];

type BrandVoiceQueryPatchData = {
  missionStatement?: string;
  newValues?: BrandVoiceItem[];
  updatedValues?: BrandVoiceItem[];
  removedValueIds?: BrandVoiceItem["id"][];
};

const getQueryKey = (brandId: string) => ["brand/voice", brandId];

const useBrandVoiceQuery = () => {
  const activeBrandID = useActiveBrandID();
  const result = useQuery<BrandVoiceResponse>({
    queryKey: getQueryKey(activeBrandID),
    queryFn: async () => {
      const { data } = await axios.get(`/api/v1/brand/${activeBrandID}/voice`);
      return data;
    },
    enabled: !!activeBrandID,
  });

  return result;
};

export const useBrandVoiceQueryDataPatch = () => {
  const queryClient = useQueryClient();
  return ({
    brandId,
    patchData: { missionStatement, newValues, updatedValues, removedValueIds },
  }: {
    brandId: string;
    patchData: BrandVoiceQueryPatchData;
  }) => {
    queryClient.setQueryData<BrandVoiceResponse>(
      getQueryKey(brandId),
      (oldData) => {
        if (!oldData) return oldData;

        const removedIdsSet = new Set(removedValueIds);
        const patchedValues = _(oldData.values)
          .map((item) => {
            if (removedIdsSet.has(item.id)) return null;
            const patch = updatedValues?.find((patch) => patch.id === item.id);
            return patch ? { ...item, ...patch } : item;
          })
          .compact()
          .value();
        return {
          ...oldData,
          mission_statement: missionStatement ?? oldData.mission_statement,
          values: [...(newValues ?? []), ...patchedValues],
        };
      }
    );
  };
};

export default useBrandVoiceQuery;
