import { Text, TextProps } from "@radix-ui/themes";
import React, { useMemo } from "react";
import styled, { CSSProperties } from "styled-components";
import { assertNever } from "~/utils/typeUtils";

const TextClamp = styled(Text)<{
  $clamp?: number;
  $wordBreak?: CSSProperties["wordBreak"];
}>`
  display: -webkit-box;
  -webkit-line-clamp: ${({ $clamp }) => $clamp};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: ${({ $wordBreak }) => $wordBreak || "break-all"};
  max-width: 100%;
`;

type DraperTextProps = TextProps & {
  clamp?: number;
  colorOverride?: string;
  variant?: "primary" | "secondary" | "tertiary";
  wordBreak?: CSSProperties["wordBreak"];
};

const DraperText = React.forwardRef<HTMLDivElement, DraperTextProps>(
  ({ colorOverride, clamp, variant = "primary", wordBreak, ...props }, ref) => {
    const color = useMemo(() => {
      if (colorOverride) return colorOverride;
      switch (variant) {
        case "primary":
          return "var(--text-primary)";
        case "secondary":
          return "var(--text-secondary)";
        case "tertiary":
          return "var(--text-tertiary)";
        default:
          assertNever(variant);
          return "var(--text-primary)";
      }
    }, [variant, colorOverride]);
    return (
      <TextClamp
        {...props}
        ref={ref}
        style={{
          color,
          ...props.style,
        }}
        $clamp={clamp}
        $wordBreak={wordBreak}
      >
        {props.children}
      </TextClamp>
    );
  }
);

export default DraperText;
