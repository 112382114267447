import BackgroundColorModifier from "./modifiers/BackgroundColorModifier";
import ButtonModifier from "./modifiers/ButtonModifier";
import CollectionModifier from "./modifiers/CollectionModifier";
import GeneratedVariantSelector from "./modifiers/GeneratedVariantSelector";
import ImageModifier from "./modifiers/ImageModifier";
import TextModifier from "./modifiers/TextModifier";
import {
  useEmailState,
  useUpdateSectionField,
} from "~/routes/intern/email_editor/context/EmailEditorContext";
import { isHeroCollectionSection } from "~/utils/emails/useSectionTypeCheck";

const HeroCollectionView = () => {
  const updateSection = useUpdateSectionField();

  const { selectedSectionId, sections } = useEmailState();
  const selectedSection = sections.find(
    (section) => section.id === selectedSectionId
  );

  if (!selectedSection) {
    return null;
  }

  if (!isHeroCollectionSection(selectedSection)) {
    // Shouldnt get here as we generate directly in AddBlockView
    return null;
  }

  const collection = selectedSection.collection;
  const title = collection.title;
  const description = collection.description;
  const ctaButton = collection.cta_button;
  const image = collection.background_image;

  return (
    <>
      <GeneratedVariantSelector />

      <CollectionModifier
        collections={[collection]}
        onCollectionChange={(collections) => {
          if (collections.length > 0) {
            updateSection({
              sectionId: selectedSectionId,
              field: "collection",
              value: collections[0],
            });
          }
        }}
        min={1}
        max={1}
      />

      <TextModifier
        textElement={title}
        label="Title"
        enabled={title.enabled ?? false}
        fieldName="collection.title.text"
        onSwitchToggle={(enabled) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "collection.title.enabled",
            value: enabled,
          });
        }}
      />
      <TextModifier
        textElement={description}
        label="Description"
        enabled={description.enabled ?? false}
        fieldName="description.text"
        onSwitchToggle={(enabled) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "collection.description.enabled",
            value: enabled,
          });
        }}
      />

      <ButtonModifier
        fieldName="collection.cta_button"
        buttonElement={ctaButton}
        onSwitchToggle={(enabled) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "collection.cta_button.enabled",
            value: enabled,
          });
        }}
      />

      <ImageModifier
        enabled={image?.enabled ?? false}
        initialImageUrl={image?.image_url}
        onSwitchToggle={(enabled) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "collection.background_image.enabled",
            value: enabled,
          });
        }}
        onAssetChange={(asset) => {
          updateSection({
            sectionId: selectedSection.id,
            field: "collection.background_image.image_url",
            value: asset.url,
          });
        }}
      />

      <BackgroundColorModifier
        palette={selectedSection.palette}
        sectionId={selectedSection.id}
      />
    </>
  );
};

export default HeroCollectionView;
