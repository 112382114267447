import { STYLE_LIBRARY_ROUTE } from "../constants";
import brandVoicePageSections from "./brand-voice/sections";
import visualPageSections from "./visuals/sections";
import { Flex, Heading, SegmentedControl } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import {
  Outlet,
  RouteObject,
  useLocation,
  useNavigate,
} from "react-router-dom";
import DraperSegmentControl from "~/components/core/DraperSegmentedControl";
import BackgroundGradient from "~/components/core/layout/BackgroundGradient";
import StyleLibraryPage from "~/components/style-library/StyleLibraryPage";
import { BrandStylingProvider } from "~/contexts/BrandStylingContext";
import BrandVoiceProvider from "~/contexts/BrandVoiceContext";

enum Routes {
  VISUALS = "",
  VOICE = "voice",
}

const getFullRelativePath = (route: Routes) => {
  return `${STYLE_LIBRARY_ROUTE}/${route}`;
};
interface StyleLibraryTab {
  route: Routes;
  relativePath: string;
  label: string;
}
const TABS: StyleLibraryTab[] = [
  {
    route: Routes.VISUALS,
    relativePath: getFullRelativePath(Routes.VISUALS),
    label: "Visuals",
  },
  {
    route: Routes.VOICE,
    relativePath: getFullRelativePath(Routes.VOICE),
    label: "Voice",
  },
];

export const getStyleLibraryRoutes = (): RouteObject[] => [
  {
    path: Routes.VISUALS,
    element: <StyleLibraryPage sections={visualPageSections} />,
  },
  {
    path: Routes.VOICE,
    element: (
      <BrandVoiceProvider>
        <StyleLibraryPage sections={brandVoicePageSections} />
      </BrandVoiceProvider>
    ),
  },
];

const StyleLibraryRoute = () => {
  // note that we can't use `Routes` as the value, as the radix-ui SegmentedControl doesn't play well with "" as value
  const [activeTab, setActiveTab] = useState(TABS[0].relativePath);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const path = location.pathname;

    if (path.includes(Routes.VOICE)) {
      setActiveTab(getFullRelativePath(Routes.VOICE));
    } else {
      setActiveTab(getFullRelativePath(Routes.VISUALS));
    }
  }, [location]);

  const handleTabChange = (relativePath: string) => {
    setActiveTab(relativePath);
    navigate(relativePath);
  };
  return (
    <BrandStylingProvider>
      <Flex m="6" direction="column" gap="6">
        <BackgroundGradient variant="3" />
        <Flex direction="row" gap="5" align="center">
          <Heading size="6">My Brand</Heading>
          <DraperSegmentControl
            $size="large"
            $color="var(--text-secondary)"
            value={activeTab}
            radius="large"
            size="3"
            onValueChange={handleTabChange}
            style={{ backgroundColor: "var(--black-8, #00000014)" }}
          >
            {TABS.map((tab) => (
              <SegmentedControl.Item value={tab.relativePath} key={tab.route}>
                <Heading
                  size="5"
                  weight="medium"
                  style={{
                    padding: "8px",
                  }}
                >
                  {tab.label}
                </Heading>
              </SegmentedControl.Item>
            ))}
          </DraperSegmentControl>
        </Flex>

        <Outlet />
      </Flex>
    </BrandStylingProvider>
  );
};

export default StyleLibraryRoute;
