import classes from "./SearchableExpandedSelect.module.css";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Box, Flex, Skeleton, Text, TextField } from "@radix-ui/themes";
import { debounce } from "lodash";
import { Check, SquareArrowOutUpRightIcon } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAuthenticatedUserState } from "~/contexts/CurrentUserContext";
import useShopifyCollectionsQuery, {
  Collection,
} from "~/hooks/shopify/useShopifyCollectionsQuery";

const SearchableExpandedSelect = <T,>({
  onSelect,
  height,
  selected,
  showExternalLink = true,
}: {
  onSelect: (collection: Collection) => void;
  height?: string;
  selected: string[];
  showExternalLink?: boolean;
}) => {
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const { activeBrand } = useAuthenticatedUserState();

  const {
    data: collections,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useShopifyCollectionsQuery({
    query: debouncedSearch,
  });

  const debouncedSearchCall = useCallback(
    debounce((value: string) => {
      // This function will only be called after 300ms of inactivity
      setDebouncedSearch(value);
    }, 300),
    []
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);
    debouncedSearchCall(value);
  };

  useEffect(() => {
    return () => {
      debouncedSearchCall.cancel();
    };
  }, [debouncedSearchCall]);

  return (
    <Flex
      direction="column"
      style={{
        border: "1px solid #DDD7D7",
        borderRadius: "16px",
      }}
    >
      <Box style={{ padding: "16px", borderBottom: "1px solid #DDD7D7" }}>
        <TextField.Root
          value={search}
          onChange={handleSearchChange}
          style={{ backgroundColor: "#F1F1F0", width: "100%" }}
          radius="large"
          color="teal"
          variant="soft"
          placeholder="Search…"
        >
          <TextField.Slot>
            <MagnifyingGlassIcon height="16" width="16" />
          </TextField.Slot>
        </TextField.Root>
      </Box>

      {isLoading ? (
        <Skeleton
          height="32px"
          width="auto"
          mx="4"
          my="2"
          style={{ borderRadius: "12px" }}
        />
      ) : (
        <InfiniteScroll
          height={height ?? "196px"}
          next={() => {
            fetchNextPage();
          }}
          hasMore={hasNextPage ?? false}
          dataLength={
            collections?.pages.flatMap((page) => page.collections).length ?? 0
          }
          loader={
            <Skeleton
              height="32px"
              width="auto"
              mx="4"
              my="2"
              style={{ borderRadius: "12px" }}
            />
          }
          scrollThreshold={"90%"}
        >
          <Flex direction="column" gap="8px" py="12px" px="16px">
            {collections?.pages
              .flatMap((page) => page.collections)
              .map((collection) => {
                const isSelected = selected?.includes(collection.id);
                return (
                  <Flex
                    onClick={() => {
                      onSelect(collection);
                    }}
                    className={classes.option}
                    justify="between"
                    align="center"
                    key={collection.id}
                    style={{
                      cursor: "pointer",
                      backgroundColor: isSelected ? "#F1F1F0" : undefined,
                    }}
                  >
                    <Flex direction="row" gap="4" align="center">
                      {isSelected && <Check size={16} />}
                      <Text>{collection.title}</Text>
                    </Flex>

                    {showExternalLink && (
                      <SquareArrowOutUpRightIcon
                        style={{
                          color: "#136EC1",
                          width: "20px",
                          height: "20px",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(
                            `${activeBrand.domain}/collections/${collection.handle}`,
                            "_blank"
                          );
                        }}
                      />
                    )}
                  </Flex>
                );
              })}
          </Flex>
        </InfiniteScroll>
      )}
    </Flex>
  );
};

export default SearchableExpandedSelect;
