import { useActiveBrandID } from "../../../contexts/CurrentUserContext";
import ChatMessageContainer from "../../chat/ChatMessageContainer";
import AppButton from "../../core/buttons/AppButton/AppButton";
import CampaignPublishDialogChatEmailContainer from "./CampaignPublishDialogChatEmailContainer";
import { operations } from "@openapi";
import { Flex, Select, Text, Avatar } from "@radix-ui/themes";
import { useState } from "react";
import { toast } from "sonner";
import styled from "styled-components";
import useMetaAdAccountsQuery from "~/hooks/meta/useMetaAdAccountsQuery";
import useMetaAdsetsQuery from "~/hooks/meta/useMetaAdsetsQuery";
import useMetaBusinessPagesQuery from "~/hooks/meta/useMetaBusinessPagesQuery";
import useMetaCampaignsQuery from "~/hooks/meta/useMetaCampaignsQuery";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

const Root = styled(Flex)`
  border-right: 1px solid var(--border-primary);
  width: 430px;
  flex-shrink: 0;
  height: 100%;
  padding: 24px;
  gap: 24px;
`;

const SelectTrigger = styled(Select.Trigger)`
  height: 48px;
  border-radius: 12px;
`;

type GenMetaAdCreativeResponse =
  operations["meta_api_gen_meta_ad_creative"]["responses"]["200"]["content"]["application/json"];
type GenMetaAdCreativeRequestData =
  operations["meta_api_gen_meta_ad_creative"]["requestBody"]["content"]["application/json"];

const CampaignPublishDialogChatContainer = ({
  campaignId,
  selectedAds,
  selectedEmails,
  selectedCampaignType,
  toggleSelectedCampaignType,
  isDisabled,
  errorMessage,
}: {
  campaignId: string;
  toggleSelectedCampaignType: () => void;
  selectedEmails: string[];
  selectedAds: string[];
  selectedCampaignType: "email" | "ads";
  isDisabled: boolean;
  errorMessage: string;
}) => {
  const [selectedAdAccount, setSelectedAdAccount] = useState<string | null>(
    null
  );
  const [selectedCampaign, setSelectedCampaign] = useState<string | null>(null);
  const [selectedAdSet, setSelectedAdSet] = useState<string | null>(null);
  const [selectedPage, setSelectedPage] = useState<string | null>(null);

  const activeBrandID = useActiveBrandID();
  const { adAccounts } = useMetaAdAccountsQuery(activeBrandID);
  const {
    campaignsData,
    fetchNextCampaigns,
    hasNextCampaigns,
    isFetchingNextCampaigns,
  } = useMetaCampaignsQuery(selectedAdAccount);
  const { adSetsData, fetchNextAdSets, hasNextAdSets, isFetchingNextAdSets } =
    useMetaAdsetsQuery(selectedAdAccount, selectedCampaign);
  const { businessPages } = useMetaBusinessPagesQuery(selectedAdAccount);

  const campaigns =
    campaignsData?.pages.flatMap((page) => page.campaigns) ?? [];
  const adSets = adSetsData?.pages.flatMap((page) => page.ad_sets) ?? [];

  const createAdCreativeMutation = useDraperApiPostMutation<
    GenMetaAdCreativeResponse,
    GenMetaAdCreativeRequestData
  >({
    path: "/meta/create-ad-creative",
    mutationKey: ["create-ad-creative"],
    onSuccess: () => {
      toast.success("Ad created");
    },
    onError: (error) => {
      toast.error("Ad creation failed");
    },
  });

  const [publishState, setPublishState] = useState<
    "initial" | "loading" | "success" | "scheduling"
  >("initial");

  const handlePublishAds = () => {
    if (selectedAdAccount && selectedAdSet) {
      createAdCreativeMutation.mutate({
        meta_ad_account_id: selectedAdAccount,
        adset_id: selectedAdSet,
        ad_creative_ids: selectedAds,
      });
    }
  };

  if (selectedCampaignType === "email") {
    return (
      <CampaignPublishDialogChatEmailContainer
        campaignId={campaignId}
        selectedEmails={selectedEmails}
        errorMessage={errorMessage}
        isDisabled={isDisabled}
        setPublishState={setPublishState}
        publishState={publishState}
        toggleSelectedCampaignType={toggleSelectedCampaignType}
      />
    );
  }

  return (
    <Root direction={"column"}>
      <ChatMessageContainer
        message={`
        You have selected ${
          selectedAds.length === 1
            ? "1 Meta ad"
            : `${selectedAds.length} Meta ads`
        } to connect to a campaign.
      `}
      />
      <Flex direction="column" gap="12px">
        <Text weight="medium">Meta Account</Text>
        <Select.Root
          value={selectedAdAccount || undefined}
          onValueChange={(value) => {
            setSelectedAdAccount(value);
            setSelectedCampaign(null);
            setSelectedAdSet(null);
          }}
        >
          <SelectTrigger color="gray" placeholder="Select Meta Account" />
          <Select.Content>
            {adAccounts?.map((account) => (
              <Select.Item key={account.account_id} value={account.account_id}>
                <Flex align="center" gap="2">
                  <Avatar
                    size="1"
                    src={account.profile_picture_url ?? undefined}
                    fallback={account.name[0]}
                    radius="medium"
                  />
                  <Text>{account.name}</Text>
                </Flex>
              </Select.Item>
            ))}
          </Select.Content>
        </Select.Root>
      </Flex>
      {selectedAdAccount && (
        <Flex direction="column" gap="12px">
          <Text weight="medium">Meta Campaign</Text>
          <Select.Root
            value={selectedCampaign || undefined}
            onValueChange={(value) => {
              setSelectedCampaign(value);
              setSelectedAdSet(null);
            }}
          >
            <SelectTrigger color="gray" placeholder="Select Meta Campaign" />
            <Select.Content>
              {campaigns.map((campaign) => (
                <Select.Item key={campaign.id} value={campaign.id}>
                  {campaign.name}
                </Select.Item>
              ))}
              {hasNextCampaigns && (
                <Select.Item
                  value="load-more"
                  onSelect={() => fetchNextCampaigns()}
                >
                  {isFetchingNextCampaigns ? "Loading..." : "Load more..."}
                </Select.Item>
              )}
            </Select.Content>
          </Select.Root>
        </Flex>
      )}
      {selectedAdAccount && selectedCampaign && (
        <Flex direction="column" gap="12px">
          <Flex justify="between" align="center">
            <Text weight="medium">Ad Set</Text>
            {/* <AppButton variant="ghost" radius="large" size="2">
              <PlusIcon size="20" /> New
            </AppButton> */}
          </Flex>
          <Select.Root
            value={selectedAdSet || undefined}
            onValueChange={(value) => setSelectedAdSet(value)}
          >
            <SelectTrigger color="gray" placeholder="Select Ad Set" />
            <Select.Content>
              {adSets.map((adSet) => (
                <Select.Item key={adSet.id} value={adSet.id}>
                  {adSet.name}
                </Select.Item>
              ))}
              {hasNextAdSets && (
                <Select.Item
                  value="load-more"
                  onSelect={() => fetchNextAdSets()}
                >
                  {isFetchingNextAdSets ? "Loading..." : "Load more..."}
                </Select.Item>
              )}
            </Select.Content>
          </Select.Root>
        </Flex>
      )}
      {selectedAdAccount && selectedCampaign && selectedAdSet && (
        <Flex direction="column" gap="12px">
          <Text weight="medium">Publishing Page</Text>
          <Select.Root
            value={selectedPage || undefined}
            onValueChange={(value) => setSelectedPage(value)}
          >
            <SelectTrigger color="gray" placeholder="Select Publishing Page" />
            <Select.Content>
              {(businessPages ?? []).map((page) => (
                <Select.Item key={page.id} value={page.id}>
                  <Flex align="center" gap="2">
                    <Avatar
                      size="1"
                      src={page.picture_url}
                      fallback={page.name[0]}
                    />
                    <Text>{page.name}</Text>
                  </Flex>
                </Select.Item>
              ))}
            </Select.Content>
          </Select.Root>
        </Flex>
      )}
      {selectedAdAccount &&
        selectedCampaign &&
        selectedAdSet &&
        selectedPage && (
          <AppButton
            radius="large"
            style={{ width: "max-content" }}
            onClick={handlePublishAds}
            disabled={createAdCreativeMutation.isPending}
          >
            {createAdCreativeMutation.isPending
              ? "Publishing..."
              : "Publish Ads"}
          </AppButton>
        )}
    </Root>
  );
};

export default CampaignPublishDialogChatContainer;
