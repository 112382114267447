import { operations } from "@openapi";
import { toast } from "sonner";
import { useDraperApiPostMutation } from "~/utils/useDraperMutation";

export type InitEmailSectionElementMutationParams =
  operations["emails_api_init_section_element"]["requestBody"]["content"]["application/json"];
type Response =
  operations["emails_api_init_section_element"]["responses"][200]["content"]["application/json"];

const useInitEmailSectionElementMutation = ({
  onSuccess,
}: {
  onSuccess?: (data: Response) => void;
} = {}) => {
  return useDraperApiPostMutation<
    Response,
    InitEmailSectionElementMutationParams
  >({
    path: "/emails/section/element/init",
    onSuccess: (data) => {
      onSuccess?.(data);
    },
    onError: (error) => {
      toast.error("Failed to initialize section element");
    },
  });
};

export default useInitEmailSectionElementMutation;
