import { operations } from "@openapi";
import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";

type AdSetResponse =
  operations["meta_api_get_meta_adsets"]["responses"][200]["content"]["application/json"];

const useMetaAdsetsQuery = (
  selectedAdAccount: string | null,
  selectedCampaign: string | null
) => {
  const {
    data: adSetsData,
    fetchNextPage: fetchNextAdSets,
    hasNextPage: hasNextAdSets,
    isFetchingNextPage: isFetchingNextAdSets,
  } = useInfiniteQuery<AdSetResponse>({
    queryKey: ["adSets", selectedAdAccount, selectedCampaign],
    queryFn: async ({ pageParam = null }) => {
      if (!selectedAdAccount || !selectedCampaign)
        return { ad_sets: [], cursor: null };
      const response = await axios.get("/api/v1/meta/adsets", {
        params: {
          meta_ad_account_id: selectedAdAccount,
          campaign_id: selectedCampaign,
          limit: 100,
          after: pageParam,
        },
      });
      return response.data;
    },
    enabled: !!selectedAdAccount && !!selectedCampaign,
    getNextPageParam: (lastPage) => lastPage.cursor,
    initialPageParam: null,
  });

  return {
    adSetsData,
    fetchNextAdSets,
    hasNextAdSets,
    isFetchingNextAdSets,
  };
};

export default useMetaAdsetsQuery;
