import * as Form from "@radix-ui/react-form";
import { Flex, TextField } from "@radix-ui/themes";
import { useState } from "react";
import { toast } from "sonner";
import DraperText from "~/components/core/DraperText";
import AppButton from "~/components/core/buttons/AppButton/AppButton";
import { useActiveBrandID } from "~/contexts/CurrentUserContext";
import { BrandVoiceItem } from "~/hooks/voices/useBrandVoiceQuery";
import { usePatchBrandVoiceMutation } from "~/hooks/voices/usePatchBrandVoiceQuery";

interface VoiceGuidelineItemFormProps {
  item: Pick<BrandVoiceItem, "category"> &
    Partial<Omit<BrandVoiceItem, "category">>;
  onClose: () => void;
}

const hasId = (
  item: VoiceGuidelineItemFormProps["item"]
): item is Omit<VoiceGuidelineItemFormProps["item"], "id"> & {
  id: BrandVoiceItem["id"];
} => !!item.id;

interface FormType {
  value: string;
  example: string;
}

const VoiceGuidelineItemForm: React.FC<VoiceGuidelineItemFormProps> = ({
  item,
  onClose,
}) => {
  const activeBrandID = useActiveBrandID();
  const [hasChanges, setHasChanges] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const { mutate, isPending: isLoading } = usePatchBrandVoiceMutation({
    onSuccess() {
      onClose();
    },
    onError(message) {
      toast.error(message);
    },
  });

  const handleChange = (event: React.FormEvent<HTMLFormElement>) => {
    setHasChanges(true);
    const data = new FormData(event.currentTarget);
    const values = Object.fromEntries(data.entries()) as unknown as FormType;
    setIsValid(
      values.value.trim().length > 0 && values.example.trim().length > 0
    );
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const values = Object.fromEntries(data.entries()) as unknown as FormType;
    const updatedItem = { ...item, ...values };

    mutate({
      brandId: activeBrandID,
      ...(hasId(updatedItem)
        ? { update_values: [updatedItem] }
        : { new_values: [updatedItem] }),
    });
  };

  return (
    <Form.Root onSubmit={handleSubmit} onChange={handleChange}>
      <Flex direction="column" gap="5">
        <Flex direction="column" gap="4">
          <Form.Field name="value">
            <Form.Label>
              <DraperText size="2" variant="secondary">
                Value
              </DraperText>
            </Form.Label>
            <Form.Control asChild>
              <TextField.Root
                size="3"
                radius="large"
                color="gray"
                required
                type="text"
                defaultValue={item.value}
              />
            </Form.Control>
            <Form.Message match="valueMissing">
              Please enter a value.
            </Form.Message>
          </Form.Field>
          <Form.Field name="example">
            <Form.Label>
              <DraperText size="2" variant="secondary">
                Example
              </DraperText>
            </Form.Label>
            <Form.Control asChild>
              <TextField.Root
                size="3"
                radius="large"
                color="gray"
                required
                type="text"
                defaultValue={item.example}
              />
            </Form.Control>
            <Form.Message match="valueMissing">
              Please enter an example.
            </Form.Message>
          </Form.Field>
        </Flex>

        <Flex justify="end" gap="3" align="center">
          <AppButton
            variant="outlined"
            radius="large"
            disabled={isLoading}
            onClick={onClose}
          >
            Cancel
          </AppButton>
          <Form.Submit asChild>
            <AppButton
              variant="dark"
              radius="large"
              disabled={!hasChanges || isLoading || !isValid}
            >
              Save Changes
            </AppButton>
          </Form.Submit>
        </Flex>
      </Flex>
    </Form.Root>
  );
};

export default VoiceGuidelineItemForm;
