import {
  CampaignDetailResponse,
  getCampaignDetailsQueryKey,
} from "../campaign/useGetCampaignDetails";
import { operations } from "@openapi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";

export type PatchEmailRequestData =
  operations["emails_api_patch_email_creative"]["requestBody"]["content"]["application/json"] & {
    campaignId?: string;
  };

export const usePatchEmailCreativeMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (vars: PatchEmailRequestData) => void;
  onError?: (message: string, error?: AxiosError) => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation<null, AxiosError, PatchEmailRequestData>({
    mutationFn: async (request_data) => {
      const { data } = await axios.patch(
        `/api/v1/emails/email-creative/${request_data.id}`,
        request_data,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken") ?? "",
          },
        }
      );
      return data;
    },
    onSuccess: (_, vars) => {
      onSuccess?.(vars);

      if (!vars.campaignId) {
        return;
      }
      queryClient.setQueryData<CampaignDetailResponse>(
        getCampaignDetailsQueryKey(vars.campaignId),
        (oldData) => {
          console.log("oldData", oldData, vars);
          if (!oldData) return oldData;
          return {
            ...oldData,
            email_creatives: oldData.email_creatives.map((creative) =>
              creative.id === vars.id
                ? {
                    ...creative,
                    title: vars.name ?? creative.title,
                    audiences: vars.audiences ?? creative.audiences,
                  }
                : creative
            ),
          };
        }
      );
    },
    onError: (error) => {
      console.log("Email creative patch failed:", error);
      const errorMessage =
        error.status !== 500 && error.response?.data
          ? String(error.response?.data)
          : "Something went wrong";
      onError?.(errorMessage, error);
    },
  });
};
